// src/App.js
import React, { useEffect, useState } from 'react';
import Web3, { eth } from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import './App.css';
import { throttle } from 'lodash'; // lodash throttle for throttling the function calls
import { Fetcher, Route, Token, Trade, TradeType, TokenAmount, Percent } from '@uniswap/sdk';

let signatureSave = null;
let pageSave = "None";
const TARGET_CHAIN_ID = '0x2105'; // Chain ID in hexadecimal (8453 in decimal)
let lastUpdateTime = 0;
let lastServerTransactionTime = 0;
const App = () => {
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [recieveOn, setRecieveOn] = useState("Wallet");
  const [sendFrom, setSendFrom] = useState("Server");
  const [buyMode, setBuyMode] = useState(0);
  const [swapMode, setSwapMode] = useState(0);
  const [bridgeMode, setBridgeMode] = useState(0);
  const [bridgeInput, setBridgeInput] = useState(0);
  const [sellPath, setSellPath] = useState(0);


  const [AllDuels, setAllDuels] = useState([]);


  const [backgroundImageUrl, setBackgroundImageUrl] = useState("/Background2.png");

  const [ethBalance, setEthBalance] = useState(0);

  const [signatureReal, setSignatureReal] = useState(null);

  const [savedTransactionHash, setSavedTransactionHash] = useState(null);

  const [message, setMessage] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [page, setPage] = useState("Home");
  const [pageStarted, setPageStarted] = useState(false);
  const [airdropInput, setAirdropInput] = useState(0.000);



  const [gasPrice, setGasPrice] = useState(0);
  const [tokenBalanceWallet, setTokenBalanceWallet] = useState(0);
  const [tokenBalanceServer, setTokenBalanceServer] = useState(0);
  const [approvedTokensBridge, setApprovedTokensBridge] = useState(0);
  const [approvedTokensSwap, setApprovedTokensSwap] = useState(0);


  const bridgeAddress = "0x15310918d75318C68da7dcA726Ff98C1BEF38587";
  const basecraftAddress = "0x0DECE059dC9db9fD51AfF4a9c2a38ca6b7d3484C";

  
  const swapperAddress = '0xD603A2A35BAA9A797C8aBa5B4f7a7C5f0Eb77580'; 





  const swapperABI = require('./swapperABI.js'); 
  const basecraftABI = require('./basecraftABI.js'); 
  const bridgeABI = require('./bridgeABI.js'); 

  const [serverIP, setServerIP] = useState("35.91.182.80:8443");
  const MessageToSign = "This signature is used to verify wallet ownership to the bobbywip server.";
  const messageToSign = '\x19Ethereum Signed Message:\n' + MessageToSign.length + MessageToSign;
  

  const [sellPopup, setSellPopup] = useState(false);
  const [transferPopup, setTransferPopup] = useState(false);
  const [createPopup, setCreatePopup] = useState(false);


  const [mainPopup, setMainPopup] = useState(false);
  const [mainPopupTitle, setMainPopupTitle] = useState("None");
  const [mainPopupDescription, setMainPopupDescription] = useState("None");
  const [mainPopupPath, setMainPopupPath] = useState("./LoginSuccess.png");
  const [mainPopupButtonText, setMainPopupButtonText] = useState("None");
  const [mainPopupButton, setMainPopupButton] = useState(0);

  const [loginPopup, setLoginPopup] = useState(true);

  const [metamaskInstalled, setMetamaskInstalled] = useState(false);
  const [timestamp, setTimestamp] = useState(0);



  const [amountIn, setAmountIn] = useState('');
  const [amountOut, setAmountOut] = useState('0');
  const [slippage, setSlippage] = useState(0.5);
  const [tokenPrice, setTokenPrice] = useState(0.000000);
  const [ethToUSD, setETHTOUSD] = useState(0.000000);
  const [ourDuel, setOurDuel] = useState(0);


  function truncateAddress(address) {
    if (address && address.startsWith('0x') && address.length > 6) {
      return `${address.substring(0, 8)}...`;
    } else {
      return address; // Return the original address if it doesn't meet the expected format
    }
  }
  
  const DuelList = ({ duels }) => {
    const handleDuelButtonClick = (duelId) => {
      // Handle the button click for the specific duel ID
      console.log(`Button clicked for Duel ID: ${duelId}`);

      
      // Add your custom logic here
    };
  
    return (
      <div>
      {duels.map((duel) => (
        <div key={duel.verified_address} className="duel-item" style={{
          backgroundImage: "url(/Background6.png)",
          backgroundSize: 'auto', // Maintains the original size of the image
          backgroundPosition: 'center top', // Starts the image from the top center
          backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
        }}>

          



          <p className="setup-description5" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`${truncateAddress(duel.address) || 'N/A'}`}</p>
          <p className="setup-description5" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`Tokens: ${duel.duel || 'N/A'}`}</p>


<img src="./Duel.png" alt="create duel" class="duel-accept-button" onClick={() => handleAccept(duel.address)}/>
      
        </div>
      ))}
    </div>
    );
  };


  const handleAccept = async (enemy) => {

    var time = getTimestamp();

    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }


    console.log("Dueling " + enemy);
    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/accept`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: messageHex,
              signature: localSignature, 
              expectedAddress: account,
              enemyAddress: enemy,
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('Duel Accepted');
            handleRefresh(account);
            // setSignPassed(true)
          } else {
            console.error('Duel Failed');
            //fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else  {
      console.log("cannot do this for 1s");
    }

  };
  
  const handleInputChangeIn = (event) => {
    // Remove non-numeric and non-decimal characters using a regular expression
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
  
    // Ensure there is only one decimal point
    const parts = sanitizedValue.split('.');
    const decimalPart = parts.length > 1 ? `.${parts.pop()}` : '';
  
    // Limit the decimal part to three digits
    const limitedDecimalPart = decimalPart.slice(0, 5);
    const finalValue = parts.join('') + limitedDecimalPart;
  
    // Update the amountIn state
    setAmountIn(finalValue);
  
    // Use the sanitized value for calculations
    const dollars = (finalValue * 10 ** 18) / ethToUSD;
    const numberOfCoins = dollars / tokenPrice;
  
    // Update the amountOut state
    setAmountOut(numberOfCoins);
  };
  const handleInputChangeOut = (event) => {
    // Remove non-numeric and non-decimal characters using a regular expression
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
  
    // Ensure there is only one decimal point
    const parts = sanitizedValue.split('.');
    const decimalPart = parts.length > 1 ? `.${parts.pop()}` : '';
  
    // Limit the decimal part to three digits
    const limitedDecimalPart = decimalPart.slice(0, 5);
    const finalValue = parts.join('') + limitedDecimalPart;
  
    // Update the amountIn state
    setAmountIn(finalValue);
  
    // Use the sanitized value for calculations
    const dollars = finalValue * tokenPrice;
    const numberOfCoins = dollars * ethToUSD;
  
    const fixedOutput = numberOfCoins / 10 ** 18;
    // Update the amountOut state
    setAmountOut(fixedOutput);
  };
  const setMaxValueOut = (maxValue) => {
    setAmountIn(maxValue);


      
    // Use the sanitized value for calculations
    const dollars = maxValue * tokenPrice;
    const numberOfCoins = dollars * ethToUSD;
  
    const fixedOutput = numberOfCoins / 10 ** 18;
    // Update the amountOut state
    setAmountOut(fixedOutput);
  }
  const setMaxValueIn = (maxValue) => {
    setAmountIn(maxValue);


      
    // Use the sanitized value for calculations
    const dollars = (maxValue * 10 ** 18) / ethToUSD;
    const numberOfCoins = dollars / tokenPrice;
  
    // Update the amountOut state
    setAmountOut(numberOfCoins);
  }


  const pingNewServerTransaction = () => {
    const nowInMilliseconds = Date.now(); // Get current time in milliseconds
const nowInSeconds = Math.floor(nowInMilliseconds / 1000);


    lastServerTransactionTime = nowInSeconds;
  }
  const openTransaction = () => {
    newPopup("You sent a transaction!", "You can view it by clicking open.", "./LoginSuccess.png", "Open", 420);
  }

  const handleInfoButton = () => {

    if (mainPopupButton == 0) {
    } else if (mainPopupButton == 1) {
      setPage("Shop");
    } else if (mainPopupButton == 420) {
      window.open("https://basescan.org/tx/" + savedTransactionHash, '_blank'); // Open the URL in a new tab
    }

    setMainPopup(false);
    setTransferPopup(false);
    setMainPopupButton(0);
    setSavedTransactionHash(null);
    setSellPopup(false);
    setCreatePopup(false);
  }
  const closePopup = () => {
    setTransferPopup(false);
    setMainPopup(false);
    setMainPopupButton(0);
    setSavedTransactionHash(null);
    setCreatePopup(false);
    setSellPopup(false);
  }

  const openSell = () => {
    setMainPopup(false);
    setSellPopup(true);
    setTransferPopup(false);
    setCreatePopup(false);
  }
  const openTransfer = () => {
    setMainPopup(false);
    setTransferPopup(true);
    setSellPopup(false);
    setCreatePopup(false);
  }

  const openCreate = () => {
    setMainPopup(false);
    setTransferPopup(false);
    setSellPopup(false);
    setCreatePopup(true);
  }

  
  const changeSwap = () => {
    if (swapMode == 0) {
      setSwapMode(1);

    } else {
      setSwapMode(0);
    }
  }




  const newPopup = (title, desc, path, buttonText, button) => {

    setMainPopupDescription(desc);
    setMainPopupPath(path);
    setMainPopupTitle(title);
    setMainPopupButton(button);
    setMainPopupButtonText(buttonText);


    setMainPopup(true);
  }


  function getTimestamp() {
    return Date.now()
  }




  const withdrawRequest = async () => {
    if (!web3 && chainId == TARGET_CHAIN_ID) {
      console.error('Web3 not initialized');
      return;
    }

  try {
    const bridgeInstance = new web3.eth.Contract(bridgeABI, bridgeAddress);

    if (bridgeInstance) {

        let amount = bridgeInput*10**18;

    //  console.log(`${me} wants to bridge ${tokenId} x ${amount} to ${serverAddress}`);


        const gasPrice = await web3.eth.getGasPrice();
        const fastGasPrice = Math.floor(parseInt(gasPrice)*1.275); 
        const fee = 210000 * fastGasPrice;
        const gasLimit = await bridgeInstance.methods.withdraw(amount).estimateGas({
          from: account,
          value: fee.toString(),
          gasPrice: fastGasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
        });
        let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
        await bridgeInstance.methods.withdraw(amount).send({
          from: account,
          value: fee.toString(),
          gas: gasCalc.toString(),
          gasPrice: fastGasPrice.toString(), // Include gas price here
        });


        console.log('Transaction sent successfully');
        
      // setNoteMemo("Your transaction was sent!");
       //setNoteBody(`Your withdraw was sent.`);
       
      // pingNotification();

    }
  } catch (e) {
    console.log(e);
  }
  }


  const swapTokenToETH = async () => {
    if (!web3 && chainId == TARGET_CHAIN_ID) {
      console.error('Web3 not initialized');
      return;
    }

  try {
    const swapperInstance = new web3.eth.Contract(swapperABI, swapperAddress);

    if (swapperInstance) {

        let amount = amountIn*10**18;

    //  console.log(`${me} wants to bridge ${tokenId} x ${amount} to ${serverAddress}`);

        const gasPrice = await web3.eth.getGasPrice();
        const fastGasPrice = Math.floor(parseInt(gasPrice)*1.275); 
        const fee = amount;
        const gasLimit = await swapperInstance.methods.swapTokenToEth(amount, 22).estimateGas({
          from: account,
          value: "0",
          gasPrice: fastGasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
        });
        let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
        await swapperInstance.methods.swapTokenToEth(amount, 22).send({
          from: account,
          value: "0",
          gas: gasCalc.toString(),
          gasPrice: fastGasPrice.toString(), // Include gas price here
        });
       // console.log('Transaction sent successfully');
        
      // setNoteMemo("Your transaction was sent!");
       //setNoteBody(`Your withdraw was sent.`);
       
      // pingNotification();

    }
  } catch (e) {
    console.log(e);
  }
  }



  

  const swapETHtoToken = async () => {
    if (!web3 && chainId == TARGET_CHAIN_ID) {
      console.error('Web3 not initialized');
      return;
    }

  try {
    const swapperInstance = new web3.eth.Contract(swapperABI, swapperAddress);

    if (swapperInstance) {

        let amount = amountIn*10**18;

    //  console.log(`${me} wants to bridge ${tokenId} x ${amount} to ${serverAddress}`);

        const gasPrice = await web3.eth.getGasPrice();
        const fastGasPrice = Math.floor(parseInt(gasPrice)*1.275); 
        const fee = amount;
        const gasLimit = await swapperInstance.methods.swapEthToToken(amount, 22).estimateGas({
          from: account,
          value: fee.toString(),
          gasPrice: fastGasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
        });
        let gasCalc = Math.floor(parseInt(gasLimit) * 1.1);
        await swapperInstance.methods.swapEthToToken(amount, 22).send({
          from: account,
          value: fee.toString(),
          gas: gasCalc.toString(),
          gasPrice: fastGasPrice.toString(), // Include gas price here
        });
       // console.log('Transaction sent successfully');
        
      // setNoteMemo("Your transaction was sent!");
       //setNoteBody(`Your withdraw was sent.`);
       
      // pingNotification();

    }
  } catch (e) {
    console.log(e);
  }
  }





  const approveSwap = async () => {
    if (!web3 && chainId == TARGET_CHAIN_ID) {
      console.error('Web3 not initialized');
      return;
    }

  try {
    const instance = new web3.eth.Contract(basecraftABI, basecraftAddress);

    if (instance) {

        const gasPrice = await web3.eth.getGasPrice();
        const fastGasPrice = Math.floor(parseInt(gasPrice)*1.1); 
        const gasLimit = await instance.methods.approve(swapperAddress, (1000000*(10**18))).estimateGas({
          from: account,
          value: "0",
          gasPrice: fastGasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
        });
        let gasCalc = Math.floor(parseInt(gasLimit)*1.1);
        await instance.methods.approve(swapperAddress, (1000000*(10**18))).send({
          from: account,
          gas: gasCalc.toString(),
          gasPrice: fastGasPrice.toString(), // Include gas price here
        });
       // console.log('Transaction sent successfully');
       setApprovedTokensSwap((1000000*(10**18)));

    }
  } catch (e) {
    console.log(e);
  }
  }



  const approveBridge = async () => {
    if (!web3 && chainId == TARGET_CHAIN_ID) {
      console.error('Web3 not initialized');
      return;
    }

  try {
    const instance = new web3.eth.Contract(basecraftABI, basecraftAddress);

    if (instance) {



        const gasPrice = await web3.eth.getGasPrice();
        const fastGasPrice = Math.floor(parseInt(gasPrice)*1.1); 
        const gasLimit = await instance.methods.approve(bridgeAddress, (1000000*(10**18))).estimateGas({
          from: account,
          value: "0",
          gasPrice: fastGasPrice.toString(), // Convert back to string, as web3 requires string for gasPrice
        });
        let gasCalc = Math.floor(parseInt(gasLimit)*1.1);
        await instance.methods.approve(bridgeAddress, (1000000*(10**18))).send({
          from: account,
          gas: gasCalc.toString(),
          gasPrice: fastGasPrice.toString(), // Include gas price here
        });
       // console.log('Transaction sent successfully');
       setApprovedTokensBridge((1000000*(10**18)));

    }
  } catch (e) {
    console.log(e);
  }
  }






  const handleBridge = async () => {
   // console.log(`server is ${online}`);

    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      
      if (bridgeInput >= 1 && approvedTokensBridge >= bridgeInput) {
        
        if (!web3 && chainId == TARGET_CHAIN_ID) {
          console.error('Web3 not initialized');
          return;
        }


        setTimestamp(time);
        

        try {
          
          let bridgeContract = new web3.eth.Contract(bridgeABI, bridgeAddress);
          let bigNum = (bridgeInput * 10 ** 18);
          
          const gasLimit = await bridgeContract.methods.bridge(bigNum).estimateGas({ from: account });
          
          const maxPriorityFeePerGas = web3.utils.toWei('0.005', 'gwei'); // Example priority fee of 1 gwei
          const maxFeePerGas = web3.utils.toWei('0.3', 'gwei'); // Example max fee of 50 gwei
          
          // Send the transaction with the specified gas amount
          // Send the transaction to the 'bridge' function with the specified amount
          const transaction = await bridgeContract.methods.bridge(bigNum).send({
              from: account,
              gas: gasLimit,
              maxPriorityFeePerGas: maxPriorityFeePerGas,
              maxFeePerGas: maxFeePerGas
          });

          console.log('Bridge transaction successful:', transaction);
          setSavedTransactionHash(transaction.transactionHash);
          //fetchUserDataByVerifiedAddress(account);
         // setPage("Transaction Sent");
          bridgeContract = null;
          } catch (error) {
          console.error('Error bridging tokens:', error.message);
          }
    
      } else {

        if (bridgeInput >= 1) {
          console.log("Must approve tokens first");
          approveBridge();
        } else {
          console.log("Must bridge atleast 1");
        }
    
      }
    } else {
      console.log("Cannot click this again for 1s");
    }


  };




  const handleRemove = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }


    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (localSignature  !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/remove`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: messageHex,
              signature: localSignature, // Fix: Use signatureSave instead of signature
              expectedAddress: account
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('Duel Remove succeeded');
            //fetchUserDataByVerifiedAddress(account);
            handleRefresh(account);
            // setSignPassed(true)
          } else {
            console.error('Duel Remove failed');
            //fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("cannot do this for 1s");
    }
    
  };


  const handleCreate = async () => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }


    
    var time = getTimestamp();

    if (timestamp + 1000 <= time) {
      setTimestamp(time);
      if (signatureSave !== null) {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
    
        try {
          const response = await fetch(`https://${serverIP}/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: messageHex,
              signature: localSignature, // Fix: Use signatureSave instead of signature
              expectedAddress: account,
              duelAmount: bridgeInput,
            }),
          });
    
          const data = await response.json();
          
          if (data.success) {
            
            console.log('Duel Create succeeded');
            handleRefresh(account);
            setCreatePopup(false);
            // setSignPassed(true)
          } else {
            console.error('Duel Create failed');
           // fetchUserDataByVerifiedAddress(account);
            // setSignPassed(false);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    } else {
      console.log("cannot do this for 1s");
    }
    
  };



  const handleRefresh = async (addy) => {
    let localSignature = null;
    if (signatureSave) {
      localSignature = signatureSave;
    } else if (signatureReal) {
      localSignature = signatureReal;
    }


    if (localSignature != null) {

      const encoder = new TextEncoder();
      const messageUint8 = encoder.encode(messageToSign);
      const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
      setMessage(messageHex);





      const checksumAddy = Web3.utils.toChecksumAddress(addy);


    fetch(`https://${serverIP}/data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: messageHex,
        signature: localSignature,
        expectedAddress: checksumAddy,
      })
    })
    .then(response => response.json())
    .then(data => {
      // Log the parsed JSON data
      console.log('Received data:', data);
    
      let tokensFixed = (data.token_balance).toFixed(3);
      setTokenBalanceServer(tokensFixed);
      setGasPrice(data.gasPrice);
      setTokenPrice(data.token_price);
      setETHTOUSD(data.eth_to_usd);
      setOurDuel(data.duel);
      setAllDuels(data.duels || []);

    
      // Log the duels to verify
      console.log('AllDuels:', data.duels || []);
    })
    .catch(error => {
      console.error('Error:', error);
    });



  } else {
    console.log("Local Signature is not found!");
  }

  };


  const handleSwitch2 = () => {
    if (buyMode == 0) {
      setBuyMode(1);
    } else {
      setBuyMode(0);
    }
  }


  const handleChangePath = (mode) => {
    setSellPath(mode);
  }


  const handleSwitch = () => {
    if (bridgeMode == 0) {
      setBridgeMode(1);
      setRecieveOn("Server");
      setSendFrom("Wallet");
    } else {
      setBridgeMode(0);
      setRecieveOn("Wallet");
      setSendFrom("Server");
    }
  }

  const handleBridgeChange = (event) => {
    setBridgeInput(event.target.value);
  };



  const handlePage = (page) => {
    setPage(page);
    pageSave = page;
    if (page == "Duel") {
      setBackgroundImageUrl("./Background6.png");
    } else {
      setBackgroundImageUrl("./Background6.png");
    }
  }
  const openMetamask = (e) => {
    e.preventDefault();
    window.open("https://metamask.io/", '_blank');
  };
  const openLogin = () => {
    setLoginPopup(true);
  }
  const closeLogin = () => {
    setLoginPopup(false);
  }
  useEffect(() => {
    const initWeb3 = async () => {
      const provider = await detectEthereumProvider();

      if (provider) {

        setMetamaskInstalled(true);
        const web3Instance = new Web3(provider);


        setWeb3(web3Instance);
        const accounts = await provider.request({ method: 'eth_accounts' });


        provider.removeAllListeners('chainChanged'); // Remove previous listeners
        provider.removeAllListeners('accountsChanged'); // Remove previous listeners
  


        provider.on('chainChanged', handleChainChanged);
        provider.on('accountsChanged', handleAccountsChanged);

        await fetchChainData(provider);

        // Set an interval to check the chain ID periodically
        setInterval(async () => {
          await throttledFetchChainData(provider, accounts);
        }, 5000); // Check every 5 seconds


      } else {
        console.error('Please install MetaMask!');
        setMetamaskInstalled(false);
      }
    };

    if (!pageStarted) {
      setPageStarted(true);
      initWeb3();
    }

  }, [pageStarted]);

  const fetchChainData = async (provider, accounts) => {
    //console.log('fetchChainData called at', Date.now());
    const timestampInSeconds = Math.floor(Date.now() / 1000);
    if (timestampInSeconds >= lastUpdateTime) {
      lastUpdateTime = (Math.floor(Date.now() / 1000) + 4);
      console.log('fetchChainData called at', Date.now());
      try {
        let localSignature = null;
        if (signatureSave) {
          localSignature = signatureSave;
        } else if (signatureReal) {
          localSignature = signatureReal;
        }





        const web3Instance = new Web3(provider);

        //if (accounts && accounts[0] && localSignature) {
          if (accounts && accounts[0] && localSignature) {
          const basecraftToken = new web3Instance.eth.Contract(basecraftABI, basecraftAddress);
          const tokenBalance = await basecraftToken.methods.balanceOf(accounts[0]).call();
          let tokenNumber = parseFloat(tokenBalance.toString());
          let realTokens = ((tokenNumber / 10 ** 18).toFixed(3));
          setTokenBalanceWallet(realTokens);
          console.log("Your token balance is " + tokenBalance);



          const etherbalance = await web3Instance.eth.getBalance(accounts[0]);
          const etherFixed = parseInt(etherbalance.toString());
          const etherReduced = (etherFixed/10**18).toFixed(6);
          setEthBalance(etherReduced);
          //console.log("YOUR BALANCE IS " + etherbalance + " = " + etherReduced);




          const tokenAllowance = await basecraftToken.methods.allowance(accounts[0], bridgeAddress).call();
          let approveNumber = parseFloat(tokenAllowance.toString());
          setApprovedTokensBridge(approveNumber);


          const tokenAllowanceSwap = await basecraftToken.methods.allowance(accounts[0], swapperAddress).call();
          let approveNumberSwap = parseFloat(tokenAllowanceSwap.toString());
          setApprovedTokensSwap(approveNumberSwap);
       //   console.log("Approved to swap " + approveNumberSwap);

          const encoder = new TextEncoder();
          const messageUint8 = encoder.encode(messageToSign);
          const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
          setMessage(messageHex);





          const checksumAddy = Web3.utils.toChecksumAddress(accounts[0]);



           
      
            





          fetch(`https://${serverIP}/data`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: messageHex,
              signature: localSignature,
              expectedAddress: checksumAddy,
            })
          })
          .then(response => response.json())
          .then(data => {
            // Log the parsed JSON data
            console.log('Received data:', data);
          
            let tokensFixed = (data.token_balance).toFixed(3);
            setTokenBalanceServer(tokensFixed);
            setGasPrice(data.gasPrice);
            setTokenPrice(data.token_price);
            setETHTOUSD(data.eth_to_usd);
            setOurDuel(data.duel);
            setAllDuels(data.duels || []);

          
            // Log the duels to verify
            console.log('AllDuels:', data.duels || []);
          })
          .catch(error => {
            console.error('Error:', error);
          });



        } else {
          console.log("Local Signature is not found!");
        }








        const chainId = await provider.request({ method: 'eth_chainId' });
        setAccount(accounts[0] || null);
        setChainId(chainId);
        console.log(`Fetched Chain ID: ${chainId}`);

        if (chainId !== TARGET_CHAIN_ID) {
          await switchToTargetChain(provider);
        }
      } catch (error) {
        console.error('Error fetching chain data:', error);
      }
    }
  };
  const throttledFetchChainData = throttle(fetchChainData, 4000); // Throttling to avoid multiple calls in a short period



  const handleAccountsChanged = (accounts) => {
    setAccount(accounts[0] || null);
  };

  const handleChainChanged = () => {
    window.location.reload();
  };

  const switchToTargetChain = async (provider) => {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: TARGET_CHAIN_ID }],
      });
    } catch (error) {
      if (error.code === 4902) {
        console.error('This network is not available in your MetaMask, please add it manually');
      } else {
        console.error('Failed to switch network:', error);
      }
    }
  };

  const handleLogin = async () => {
    if (web3) {
      try {
        const accounts = await web3.eth.requestAccounts();
        setAccount(accounts[0]);
      } catch (error) {
        console.error('User denied account access');
      }
    }
  };



  

  
  
  const gasFreeSwap = async (addy) => {
    if (web3 && addy) {
      try {
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
        setMessage(messageHex);
        const checksumAddy = Web3.utils.toChecksumAddress(addy);
       // console.log(signature);
        //console.log(messageHex);
        //console.log(checksumAddy);
        
        fetch(`https://${serverIP}/gasFreeSale`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: messageHex,
            signature: signatureSave,
            expectedAddress: checksumAddy,
            tokenAmount: amountIn
          })
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            console.log('Gas free transaction was authorized');
            //fetchUserDataByVerifiedAddress(account);
            //setSignPassed(true)
            
            
            //setLoginPopup(false);
            //setPage("Wallet");
          } else {
            console.error('Gas free transaction was denied by server');
           // setSignPassed(false);
        //   signatureSave = null;
         //  setSignatureReal(null);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        //  signatureSave = null;
        //  setSignatureReal(null);
      });
      } catch (error) {
        console.error('Error signing message:', error);
       // setSignatureReal(null);
       // signatureSave = null;
      }
    }
};


  
  const signWallet = async (addy) => {
    if (web3 && addy) {
      try {
        let address = web3.utils.toChecksumAddress(addy);
        const web3Instance = new Web3(window.ethereum);
        const signature = await web3Instance.eth.personal.sign(
          messageToSign,
          address,
          ''
        );
        const encoder = new TextEncoder();
        const messageUint8 = encoder.encode(messageToSign);
        const messageHex = Array.prototype.map.call(messageUint8, (x) => ('00' + x.toString(16)).slice(-2)).join('');
        setMessage(messageHex);
        const checksumAddy = Web3.utils.toChecksumAddress(addy);
       // console.log(signature);
        //console.log(messageHex);
        //console.log(checksumAddy);
        fetch(`https://${serverIP}/verifySignature`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: messageHex,
            signature: signature,
            expectedAddress: checksumAddy,
          })
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            console.log('Signature verification succeeded');
            //fetchUserDataByVerifiedAddress(account);
            //setSignPassed(true)
            

            
            setLoginPopup(false);
            setPage("Wallet");


            signatureSave = signature;
            setSignatureReal(signature);


            handleRefresh(checksumAddy);
          } else {
            console.error('Signature verification failed');
           // setSignPassed(false);
           signatureSave = null;
           setSignatureReal(null);
          }
        })
        .catch(error => {
          console.error('Error:', error);
          signatureSave = null;
          setSignatureReal(null);
      });
      } catch (error) {
        console.error('Error signing message:', error);
        setSignatureReal(null);
        signatureSave = null;
      }
    }
};


  
  return (
    
    <div className="App">
<link rel="preconnect" href="https://fonts.googleapis.com"/>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
<link href="https://fonts.googleapis.com/css2?family=Freeman&display=swap" rel="stylesheet"></link>

<link rel="preconnect" href="https://fonts.googleapis.com"></link>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
<link href="https://fonts.googleapis.com/css2?family=VT323&display=swap" rel="stylesheet"></link>



    <header className="header">
      <img className="logo" src="Home.png" alt="Base Craft!" onClick={() => handlePage("Home")}/>

      <img className="logo" src="Shop.png" alt="Base Craft!" onClick={() => handlePage("Shop")}/>
      <img className="logo" src="Duel.png" alt="Base Craft!" onClick={() => handlePage("Duel")}/>

      {account && signatureReal != null ? (
          <img className="logo" src="Wallet.png" alt="Base Craft!" onClick={() => handlePage("Wallet")}/>
        ) : (
          <button className='button-85' onClick={openLogin}>Login</button>
        )}
      
    </header>

        




    <div>
        {
          createPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         

                <img src="./CreateQuestion.png" alt="buy or sell tokens" class="wallet-header-image"/>
                
                



                <p className="wallet-box-info" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  width: "60%",
                  height: "20%",
                  top: "10%",
                  left: "20%",
                  }}>
                    Create a duel!<br />
                    you have {tokenBalanceServer} tokens </p>












                  <div>



                  <div className="wallet-box"                    style={{
                    top: "45%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


<img src="./Create2.png" alt="create duel" class="wallet-box-max-button" onClick={handleCreate}/>




<p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

}}>{tokenBalanceServer}</p>
<img src="./Token512.png" class="wallet-box-token"/>
          <input
    type="text"
    id="numericInput"
    class="wallet-input"
    required
    autoComplete="off"
    value={bridgeInput}
    onChange={handleBridgeChange}
    placeholder="0"


    style={{

      backgroundImage: "url(/Plank.png)",
      backgroundSize: 'cover', // Ensures the image covers the entire div
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      
                    }}
                    
    />



</div>

                  </div>
                  <img src="./chanceWarning.png" alt="odds of winning" class="wallet-footer-image"/>


              

 


          
           
                <img src="./close.png" className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>







    <div>
        {
          transferPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         

              <div>

              {

                bridgeMode == 1 ? (<img src="./toServer.png" alt="buy or sell tokens" class="wallet-header-image"/>) : (<img src="./toWallet.png" alt="buy or sell tokens" class="wallet-header-image"/>)
              }
              </div>
                
                



                <div>


                {bridgeMode == 1 ? (<p className="wallet-box-info" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  width: "60%",
                  height: "20%",
                  top: "10%",
                  left: "20%",
                  }}>
                    Send from {sendFrom} to {recieveOn}<br />
                    you have {tokenBalanceWallet} tokens </p>
                  ) : (<p className="wallet-box-info" style={{
                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    width: "60%",
                    height: "20%",
                    top: "10%",
                    left: "20%",
                    }}>
                      Send from {sendFrom} to {recieveOn}<br />
                      you have {tokenBalanceServer} tokens </p>
                  )}

                </div>













                  <div>



                  {


bridgeMode == 1 ? (<div>
  
  <div className="wallet-box"                    style={{
                    top: "40%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>

<img src="./send.png" alt="use max amount" class="wallet-box-max-button" onClick={handleBridge}/>




<p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

}}>{tokenBalanceWallet}</p>
<img src="./Token512.png" class="wallet-box-token"/>
          <input
    type="text"
    id="numericInput"
    class="wallet-input"
    required
    autoComplete="off"
    value={bridgeInput}
    onChange={handleBridgeChange}
    placeholder="tokens to bridge:"


    style={{

      backgroundImage: "url(/Plank.png)",
      backgroundSize: 'cover', // Ensures the image covers the entire div
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      
                    }}
                    
    />



</div>

  
  
  </div>) : (<div>
  
  <div className="wallet-box"                    style={{
                    top: "40%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>

<img src="./send.png" alt="use max amount" class="wallet-box-max-button" onClick={withdrawRequest}/>




<p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

}}>{tokenBalanceServer}</p>
<img src="./Token512.png" class="wallet-box-token"/>
          <input
    type="text"
    id="numericInput"
    class="wallet-input"
    required
    autoComplete="off"
    value={bridgeInput}
    onChange={handleBridgeChange}
    placeholder="tokens to bridge:"


    style={{

      backgroundImage: "url(/Plank.png)",
      backgroundSize: 'cover', // Ensures the image covers the entire div
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      
                    }}
                    
    />



</div>

  
  
  </div>)

}



                  </div>









    <img src="./Swap.png" class="wallet-swap-button" onClick={handleSwitch} style={{top: "60%"}}/>














          <div>




        {

          ethBalance == 0 ? (<img src="./gasWarning.png" alt="you need gas for this" class="wallet-footer-image"/>) : (<img src="./gasAllowed.png" alt="you need gas for this" class="wallet-footer-image"/>)
        }

        </div>



              

 


          
           
                <img src="./close.png" className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>











    <div>
        {
          sellPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="sellPopup">
                <img src="./Background.png" className="popupImage" alt="background" />
         
                <div>

                {

                  sellPath == 0 ? (<img src="./buyTitle.png" alt="buy or sell tokens" class="wallet-header-image"/>) : (<img src="./sellTitle.png" alt="sell tokens for cash" class="wallet-header-image"/>)
                }
                </div>
                

                <div>
                {
                  sellPath == 0 ? (                
                  <div class="wallet-button-container">

                  <img src="./serverGray.png"  class="wallet-custom-button" onClick={() => handleChangePath(1)}/>
                  <img src="./walletGreen.png" class="wallet-custom-button"/>
                </div>) : (                
                <div class="wallet-button-container">

                <img src="./serverGreen.png"  class="wallet-custom-button"/>
                <img src="./walletGray.png" class="wallet-custom-button" onClick={() => handleChangePath(0)}/>
                </div>)
                }
                </div>
                <div>

                {
                  sellPath == 0 ? (<div>
                  


                  <img src="./routeNote.png" alt="this route uses uniswap" class="wallet-footer-image"/>
                  
                   </div>) : (<div>


                    <p className="wallet-box-info" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'cover', // Ensures the image covers the entire div
                  backgroundPosition: 'center', // Centers the image
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  width: "60%",
                  height: "20%",
                  top: "25%",
                  left: "20%",
                  }}>
                    Sell tokens directly from the server<br />
                    This uses tokens for gas! </p>




                    <div className="wallet-box"                    style={{
                    top: "55%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src="./sell.png" alt="use max amount" class="wallet-box-max-button" onClick={() => gasFreeSwap(account)}/>




              <p className="wallet-box-info" style={{

                backgroundImage: "url(/Plank.png)",
                backgroundSize: 'cover', // Ensures the image covers the entire div
                backgroundPosition: 'center', // Centers the image
                backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{tokenBalanceServer}</p>
                            <img src="./Token512.png" class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0"
                  value={amountIn}
                  onChange={handleInputChangeOut}
                  required
                  autoComplete="off"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />



                        </div>  


                   <img src="./gasFree.png" alt="this route has a fee" class="wallet-footer-image"/>
                  
                   </div>)
                }
                </div>

                <div>
                {

                  sellPath == 0 ? (<div> 



                    <div>



                    {


                      swapMode == 0 ? (<div>
                        


                        <div className="wallet-box"                    style={{
                    top: "30%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src="./max.png" alt="use max amount" class="wallet-box-max-button" onClick={() => setMaxValueIn(ethBalance)}/>




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{ethBalance}</p>
              <img src="./ETH.png" class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0 Eth"
                  value={amountIn}
                  onChange={handleInputChangeIn}
                  required
                  autoComplete="off"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />


                        </div>                











                  <img src="./Swap.png" class="wallet-swap-button" onClick={changeSwap}/>









                        

                  <div className="wallet-box"                    style={{
                    top: "65%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src="./Swap2.png" alt="use max amount" class="wallet-box-max-button" onClick={swapETHtoToken}/>




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{tokenBalanceWallet}</p>
              <img src="./Token512.png" class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0 Eth"
                  value={amountOut}
                  onChange={handleInputChangeIn}
                  required
                  disabled={true}
                  autoComplete="off"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />


                        </div>                




                        
                        </div>) :
                        
                        
                        
                        
                        
                        
                        (<div>
                        


                        <div className="wallet-box"                    style={{
                    top: "30%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                <img src="./max.png" alt="use max amount" class="wallet-box-max-button" onClick={() => setMaxValueOut(tokenBalanceWallet)}/>




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{tokenBalanceWallet}</p>
              <img src="./ETH.png" class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0"
                  value={amountIn}
                  onChange={handleInputChangeOut}
                  required
                  autoComplete="off"
                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />


                        </div>                











                  <img src="./Swap.png" class="wallet-swap-button" onClick={changeSwap}/>









                        

                  <div className="wallet-box"                    style={{
                    top: "65%",
                    left: "10%",
                    backgroundImage: "url(/woodbackground.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                  }}>


                  <div>
                  {

                    approvedTokensSwap >= amountIn*10**18 ? (<img src="./Swap2.png" alt="use max amount" class="wallet-box-max-button" onClick={swapTokenToETH}/>) : (<img src="./Approve.png" alt="use max amount" class="wallet-box-max-button" onClick={() => approveSwap(basecraftAddress)}/>)
                  }
                  </div>
                




              <p className="wallet-box-info" style={{

backgroundImage: "url(/Plank.png)",
backgroundSize: 'cover', // Ensures the image covers the entire div
backgroundPosition: 'center', // Centers the image
backgroundRepeat: 'no-repeat', // Prevents the image from repeating

              }}>{ethBalance}</p>
              <img src="./Token512.png" class="wallet-box-token"/>
                        <input
                  type="text"
                  id="numericInput"
                  class="wallet-input"
                  placeholder="0 Eth"
                  value={amountOut}
                  onChange={handleInputChangeOut}
                  required
                  autoComplete="off"
                  disabled={true}

                  style={{

                    backgroundImage: "url(/Plank.png)",
                    backgroundSize: 'cover', // Ensures the image covers the entire div
                    backgroundPosition: 'center', // Centers the image
                    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                    
                                  }}
                                  
                  />


                        </div>                




                        
                        </div>)
                    }


                    </div>
  
                              
                    </div>) : (<div>
                      
                      
                      
                      </div>) 
                }

                </div>


      
           
                <img src="./close.png" className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>



    <div>
        {
          mainPopup == true ? (<div>
            <div className="popup-overlay">
            <div className="popup">
                <img src={mainPopupPath} className="popupImage" alt="Bring Metamask" />
                <div className="popupTitle">{mainPopupTitle}</div>
                <button className="button-68" onClick={handleInfoButton}>{mainPopupButtonText}</button>
                <div className="popupFooter">{mainPopupDescription}</div>
                <img src="./close.png" className="button-5" alt="close" onClick={closePopup}/>
                </div>
          </div> 

          
            </div>) : (<div/>)

        }
        </div>


        <div>
        {
          loginPopup == true ? (<div>
            <div className="popup-overlay">
            {
              metamaskInstalled == false ? (
                <div className="popup">
                <img src="BringMetamask.png" className="popupImage" alt="Bring Metamask" />
                <div className="popupTitle">You need metamask to login</div>
                <button className="button-68" onClick={openMetamask}>Get Metamask</button>
                <div className="popupFooter">and earn $ by playing!</div>
                <img src="./close.png" className="button-5" alt="close" onClick={closeLogin}/>
                </div>) : (<div>
                  {
                    account ? (<div> 
                    {
                    signatureReal == null ? (<div className="popup">
                    <img src="Login.png" className="popupImage" alt="Bring Metamask" />
                    <div className="popupTitle">You need to sign or</div>
                    <button className="button-68" onClick={() => signWallet(account)}>Sign Message</button>
                    <div className="popupFooter">the server will ignore you</div>
                    <img src="./close.png" className="button-5" alt="close" onClick={closeLogin}/>
                    </div>) : (<div className="popup">
                    <img src="LoginSuccess.png" className="popupImage" alt="Bring Metamask" />
                    <div className="popupTitle">You are logged in!</div>
                    <img src="./close.png" className="button-5" alt="close" onClick={closeLogin}/>
                    </div>)
                    }
                    </div>) : (<div className="popup">
                        <img src="BringMetamask.png" className="popupImage" alt="Bring Metamask" />
                        <div className="popupTitle">Login with metamask</div>
                        <button className="button-68" onClick={handleLogin}>Login</button>
                        <div className="popupFooter">to connect your wallet</div>
                        <img src="./close.png" className="button-5" alt="close" onClick={closeLogin}/>
                        </div>)
                  }
                  </div>) 
            }
          </div> 

          
            </div>) : (<div/>)

        }
        </div>


        <div>

        {

          page == "Home" ? (<div/>) : (<div/>)


        }


        {

        page == "Shop" ? (<div/>) : (<div/>)


        }

        {

        page == "Wallet" || page == "Duel" ? (<div>
          
          
          
          <div className="wallet-container">
          <div className="wallet-content" style={{
  backgroundImage: `url(${backgroundImageUrl})`,
  backgroundSize: 'auto', // Maintains the original size of the image
  backgroundPosition: 'center top', // Starts the image from the top center
  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
}}>

          {
            page == "Duel" ? (          <div className="duel-content" >
          


            <div className="scrollable-content" style={{
  backgroundImage: "url(/Background4.png)",
  backgroundSize: 'auto', // Maintains the original size of the image
  backgroundPosition: 'center top', // Starts the image from the top center
  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
}}>
          {Array.isArray(AllDuels) ? <DuelList duels={AllDuels} /> : <p>Loading...</p>}
        </div>

        <img src="./duelNote.png" alt="Player Rank" className="duel-title" onClick={()=>newPopup("Risk your tokens versus other players!", "Earn tokens for free by playing!", "./BringMetamask.png", "Get Tokens!", 1)}/>
     

            {
              ourDuel == 0 ? (<img src="./Create.png" alt="create duel" className="duel-footer" onClick={openCreate}/>) : (<img src="./Remove.png" alt="remove duel" className="duel-footer" onClick={handleRemove}/>)
            }

        
   
              
        

        </div>) : (          <div className="wallet-left-content">
          

      

      </div>)
          }

        <div className="wallet-right-content" >
          <div className="wallet-avatar">
            {/* Add player avatar here */}
            <img src="./Login.png" alt="Player Avatar" />

          </div>
          <div className="wallet-user-details">
            <p><img src="./TempRank.png" alt="Player Rank" className="wallet-rank" onClick={()=>newPopup("This is your rank!", "You can upgrade your rank in the shop.", "./BringMetamask.png", "Open Shop!", 1)}/></p>
            <p className="setup-description5" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`Server Tokens: ${tokenBalanceServer}`}</p>
                  <p className="setup-description5" style={{
                  backgroundImage: "url(/Plank.png)",
                  backgroundSize: 'auto', // Maintains the original size of the image
                  backgroundPosition: 'center top', // Starts the image from the top center
                  backgroundRepeat: 'repeat', // Repeats the image both vertically and horizontally
                  }}>{`Wallet Tokens: ${tokenBalanceWallet}`}</p>

          </div>
          <div className="wallet-user-details">

          <p><img src="./bridge.png" alt="Player Rank" className="wallet-menu-button" onClick={openSell}/></p>
          <p><img src="./Transfer.png" alt="Player Rank" className="wallet-menu-button" onClick={openTransfer}/></p>
        
    
     
          </div>

        </div>
          </div>
        </div>
          
          
          
          </div>) : (<div/>)


        }


        </div>

        <div>

          {
            !savedTransactionHash ? (<div/>) : (<img src="./exclamation.png" className="notification" alt="close" onClick={openTransaction}/>)
          }
        </div>
        
  
    </div>
  );
};

export default App;
